// in src/users.js
import React, {Fragment, Component, useEffect, useCallback, useMemo} from 'react';
import {useState} from 'react';
import {useFetch} from '../hooks/useFetch';
import {
    GET_LIST,
    crudUpdateMany,
    BooleanField,
    BooleanInput,
    Button,
    List,
    Datagrid,
    DateInput,
    TextField,
    Edit,
    TopToolbar,
    ChipField,
    CreateButton,
    SimpleForm,
    NullableBooleanInput,
    ExportButton,
    ReferenceInput,
    SelectInput,
    TextInput,
    NumberInput,
    Filter,
    ImageField,
    Link,
    Show,
    SelectField,
    SimpleShowLayout,
    SingleFieldList,
    ReferenceField,
    ReferenceManyField,
    DateField,
    NumberField,
    DeleteButton,
    SaveButton,
    Toolbar,
    AutocompleteInput,
    FormDataConsumer,
    RichTextField,
    CreateController,
    withDataProvider,
    ArrayField,
    FunctionField, useListContext, Confirm, Pagination
} from 'react-admin';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import withStyles from '@material-ui/core/styles/withStyles';
import WebIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';


import BooleanNumField from "../components/BooleanNumField";
import UserLinkField from "../components/UserLinkField";
import ProductImageField from "../components/ProductImageField";
import StarRatingField from "../components/StarRatingField";
import {StandaloneMassiveAutocomplete} from "../components/MassiveAutocompleteInput";
import WarningText from "../components/WarningText";
import OutOfAppExtractionModal from "../components/OutOfAppExtractionModal";

import dataProvider from "../dataProvider";
import {SortableImageInput} from '../components/ImageInput';

import { validateUrl } from '../lib/validator';
import { loadAccessToken } from '../lib/Auth';
import { getResizedURL } from '../utils/util';
import { base64toBlob } from '../utils/data';
import {API_URL, WEBAPP_URL} from "../config";
import { productUrl } from '../lib/url';
import BulkCreateModal from '../components/BulkCreateModal';

const styles = {
    inline: {
        display: 'inline',
        marginRight: 20
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    urlFieldCell: {
        maxWidth: 140
    },
    urlField: {
        display: 'inline-block',
        textOverflow: "ellipsis",
        overflow: "hidden",
        width: "100%"
    }
}

const CustomToolbar = withStyles(styles)(props => (
    <Toolbar {...props}>
        <SaveButton disabled={props.pristine}/>
        <DeleteButton undoable={false}/>
    </Toolbar>
));

const ProductListActions = ({
    bulkActionButtons,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
    setOutOfAppModalOpen,
    setBulkCreateModalOpen
}) => (
    <TopToolbar>
        <Button label="BULK CREATE" style={{color: 'red'}} onClick={() => setBulkCreateModalOpen(true)}><WebIcon/></Button>
        {bulkActionButtons && React.cloneElement(bulkActionButtons, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        <CreateButton basePath={basePath} />
        <Button label="External create" onClick={() => setOutOfAppModalOpen(true)}><WebIcon/></Button>
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
            maxResults={1000000}
        />
    </TopToolbar>
);

export const conditionChoices = [
    { value: 'new', name: 'new' },
    { value: 'good_condition', name: '상' },
    { value: 'lightly_worn', name: '중' },
    { value: 'worn', name: '하' },
]
const ProductFilter = ({classes, basePath, ...props}) => {

    const isProductsTab = useMemo(() => {
        return basePath === '/products'
    }, [basePath]);

    const categories = useFetch(`${API_URL}/categories?sort=["name","ASC"]`);
    const subcategories = useFetch(`${API_URL}/subcategories?sort=["name","ASC"]`);
    const outOfAppChoices = [
        { value: 'all', name: 'All' },
        { value: 'in_app', name: 'In-App' },
        { value: 'out_of_app', name: 'Out-of-App' },
    ];
    const statusChoices = [
        { value: 'all', name: 'All' },
        { value: 'selling', name: 'Selling' },
        { value: 'sold', name: 'Sold' },
    ]
    const genderChoices = [
        { value: null, name: 'All' },
        { value: 'men', name: 'Men' },
        { value: 'women', name: 'Women' },
    ]
    const negotiationChoices = [
        { value: '', name: 'All' },
        { value: 'true', name: 'True' },
        { value: 'false', name: 'False' },
    ]
    return (
        <Filter {...props}>
            <SelectInput source="external_url" allowEmpty={false} choices={outOfAppChoices} optionValue="value" alwaysOn/>
            <SelectInput source="status" allowEmpty={false} choices={statusChoices} optionValue="value" alwaysOn/>
            <SelectInput source="gender" allowEmpty={true} choices={genderChoices} optionValue="value" />
            <SelectInput source="condition" allowEmpty={true} choices={conditionChoices} optionValue="value" />
            <DateInput label="created from" source="createdAt_from" alwaysOn />
            <DateInput label="created until" source="createdAt_until" alwaysOn />
            <DateInput label="sold from" source="soldAt_from" alwaysOn />
            <DateInput label="sold until" source="soldAt_until" alwaysOn />
            {isProductsTab && <DateInput label="first product upload from" source="first_product_upload_from"/>}
            {isProductsTab && <DateInput label="first product upload until" source="first_product_upload_until" />}
            {isProductsTab && <DateInput label="seller last active" source="seller_last_active" />}
            <NumberInput label="seller ID" source="seller_id" alwaysOn/>
            <NumberInput label="min price" source="min_price" alwaysOn/>
            <NumberInput label="max price" source="max_price" alwaysOn/>
            {isProductsTab && <NumberInput label="min_image_count" source="min_image_count"/>}
            {isProductsTab && <NumberInput label="max_image_count" source="max_image_count"/>}
            <TextInput label="seller name" source="seller_name" alwaysOn/>
            <TextInput label="Title" source="title" alwaysOn/>
            <TextInput label="Description" source="description" alwaysOn/>
            <TextInput label="size" source="size" alwaysOn/>
            {categories && <SelectInput label="category" source="category" choices={categories.result} optionValue="name" alwaysOn/>}
            {subcategories && <SelectInput label="subcategory" source="subcategory" choices={subcategories.result} optionValue="name" alwaysOn/>}
            <ReferenceInput source="brand_id" reference="brands" perPage={100} sort={{field: 'name', order: 'ASC'}} alwaysOn>
                <AutocompleteInput />
            </ReferenceInput>
            <ReferenceInput source="style_id" reference="styles" perPage={100} sort={{field: 'name', order: 'ASC'}} alwaysOn>
                <AutocompleteInput />
            </ReferenceInput>
            {isProductsTab && <ReferenceInput source="collection_id" reference="collections" perPage={100} alwaysOn>
                <AutocompleteInput optionText="title"/>
            </ReferenceInput>}
            <TextInput label="instagram" source="instagram" alwaysOn/>
            <BooleanInput
                label="only display sellers with instagram"
                source="is_instagram_required"
                defaultValue={false}
                alwaysOn
            />
            {isProductsTab && <BooleanInput
                label="show products only from the sellers that haven't made any sales yet"
                source="show_products_from_no_sale_sellers"
                defaultValue={false}
            />}
            <SelectInput source="is_negotiation"
                         allowEmpty={true}
                         choices={negotiationChoices}
                         optionValue="value" alwaysOn/>
            {isProductsTab && <NumberInput label="min_shipping_fee" source="min_shipping_fee"/>}
            {isProductsTab && <NumberInput label="max_shipping_fee" source="max_shipping_fee"/>}
        </Filter>
    )
};

const AddToCollectionButton = withDataProvider(({collectionId}) => {
    const dispatch = useDispatch();
    const listData = useListContext();
    const basePath = `${API_URL}/products/`

    const addCollectionProducts = async () => {
        if(!collectionId) {
            alert('collection을 선택해주세요.')
            return;
        }
        const selectedIds = listData.selectedIds;

        dispatch(crudUpdateMany('collection_products', selectedIds, { ids : selectedIds, collectionId }, basePath));
    }

    return (
        <Button label="Collection 추가" onClick={addCollectionProducts}/>
    );
});
const PostBulkActionButtons = props => {
    const [collections, setCollections] = useState([]);
    const [collectionSearchText, setCollectionSearchText] = useState('');
    const [selectedCollection, setSelectedCollection] = useState({});

    const fetchCollections = async () => {
        const response = await dataProvider(GET_LIST, 'collections', {
            pagination: {page: 1, perPage: 10000000},
            sort: {field: 'id', order: 'DESC'}
        });
        setCollections(response.data);
    }
    const onSuggestionSelected = useCallback(({suggestion}) => {
        setSelectedCollection(suggestion);
    }, []);
    useEffect(() => {
        fetchCollections();
    }, []);
    const collectionAdderStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px',
        borderRadius: '3px',
        boxShadow: '0 8px 8px 1px rgba(0,0,0,0.14), 0 6px 10px 5px rgba(0,0,0,0.12), 0 8px 5px -5px rgba(0,0,0,0.3)'
    }
    return (
    <div style={collectionAdderStyle}>
        <StandaloneMassiveAutocomplete optionValue="title" suggestions={collections}
            onSuggestionSelected={onSuggestionSelected}
            input={{value: collectionSearchText, onChange: (newValue) => setCollectionSearchText(newValue)}}
        />
        <AddToCollectionButton {...props} collectionId={selectedCollection.id}/>
    </div>
    )
};

//Multiple 상품 삭제 버튼
const DeleteBulkActionButtons = () => {
    const listData = useListContext();
    const [isOpen, setIsOpen] = useState(false);
    const [selectSellerId, setSelectSellerId] = useState(null);

    const closeConfirm = () => {
        setIsOpen(false);
    }

    const handleOnClick = () => {
        const selectedData = listData.selectedIds.map(id => listData.data[id] || null);

        const initialSellerId = selectedData[0]?.seller_id;
        const allSameSellerId = selectedData.every(item => item && item.seller_id === initialSellerId);
        if (allSameSellerId) {
            setSelectSellerId(initialSellerId);
            setIsOpen(true);
        } else {
            alert('선택한 항목의 셀러가 동일하지 않아 처리가 불가능합니다. 같은 페이지 내에서 선택한 상품만 변경 가능합니다.');
        }
    }

    const handleOnConfirm = async () => {
        closeConfirm();

        const res = await fetch(`${API_URL}/products/hide/pick`, {
            method: 'POST',
            headers: new Headers({
                'content-type': 'application/json',
                Authorization: `bearer ${loadAccessToken()}`
            }),
            body: JSON.stringify({
                sellerId: selectSellerId,
                productIds : listData.selectedIds
            })
        });
        if (res.ok) {
            alert('상품이 숨김 처리 되었습니다.');
        } else {
            const errorData = await res.json();
            const errorMessage = errorData.error;
            alert(errorMessage);
        }
    }

    return <>
        <Button label="상품 숨기기" onClick={handleOnClick} />
        {isOpen &&
            <Confirm
                isOpen={true}
                title={'선택한 상품을 모두 숨김 처리 하시겠어요?'}
                content={<div style={{ color: '#ff0000' }}>숨김 처리가 된 상품은 다시 복구할 수 없습니다.</div>}
                onConfirm={handleOnConfirm}
                onClose={closeConfirm}
            />
        }
        </>
}

const bumpModalStyles = {
    dialogContent: {
        textAlign: 'center'
    },
    dialogPaperWidthSm: {
        width: "100%"
    },
    extractButton: {
        margin: '10px'
    },
    contentProductDiv: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'center',
        padding: '10px 0 14px 0',
        borderTop: '1px solid #e6e6e6',
        borderBottom: '1px solid #e6e6e6',
        '& > .product-info': {
            padding: '2px 4px',
            '& > p': {
                margin: '2px',
                fontSize: '0.8rem'
            }
        }
    }
};

const BUMP_REQUEST_URL = `${API_URL}/products/bump`;
const BumpButton = withStyles(bumpModalStyles)(({classes, record}) => {
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const onClose = (e) => {
        e.stopPropagation();
        setIsConfirmModalOpen(false);
    }
    const requestBump = async (e) => {
        e.stopPropagation();
        try {
            const response = await fetch(BUMP_REQUEST_URL, {
                method: 'POST',
                headers: new Headers({
                    'content-type' : 'application/json',
                    Authorization: `bearer ${loadAccessToken()}`
                }),
                body: JSON.stringify({product_id: record.id})
            });
            const bumpResult = await response.json();
            alert(JSON.stringify(bumpResult));
        } catch (err) {
            alert(err);
        } finally {
            setIsConfirmModalOpen(false);
        }
    }

    return (
        <Fragment>
            <Button onClick={(e) => {
                e.stopPropagation();
                // open confirm modal
                setIsConfirmModalOpen(true);
            }} label="Bump" variant="contained"/>
            <Dialog open={isConfirmModalOpen} onClose={onClose} maxWidth="sm" classes={{paperWidthSm: classes.dialogPaperWidthSm}}>
                <DialogTitle>상단업을 적용하시겠습니까?</DialogTitle>
                <DialogContent>
                    <div className={classes.contentProductDiv}>
                        <ProductImageField record={record} />
                        <div className="product-info">
                            <p>Product ID: {record.id}</p>
                            <p>Title: {record.title}</p>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button label="Cancel" onClick={onClose} />
                    <Button label="Bump" onClick={requestBump} />
                </DialogActions>
            </Dialog>
        </Fragment>
    );
});

export const ProductList = withStyles(styles)(({classes, basePath, ...props}) => {
    const [isOutOfAppModalOpen, setOutOfAppModalOpen] = useState(false);
    const [bulkCreateModalOpen, setBulkCreateModalOpen] = useState(false);

    return (<Fragment>
        <List basePath={basePath} {...props} filters={<ProductFilter basePath={basePath}/>}
            bulkActionButtons={<>
                <PostBulkActionButtons/>
                <DeleteBulkActionButtons />
            </>}
            pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
            actions={<ProductListActions setOutOfAppModalOpen={setOutOfAppModalOpen} setBulkCreateModalOpen={setBulkCreateModalOpen}/>}
            sort={{field: 'id', order: 'DESC'}}
        >
            <Datagrid rowClick="edit">
                <ProductImageField label="Image"/>
                <BooleanNumField source="is_visible" label="product visible" sortable={false}/>
                <ReferenceField label="Seller" source="seller_id" reference="users" link="show" sortable={false}>
                    <UserLinkField/>
                </ReferenceField>
                <ReferenceField label="Seller Visible" source="seller_id" reference="users" link="show" sortable={false}>
                    <BooleanNumField source="seller.is_visible"/>
                </ReferenceField>
                <ReferenceField label="Certified" source="seller_id" reference="users" link="show" sortable={false}>
                    <BooleanNumField source="seller.is_certified" />
                </ReferenceField>
                <TextField label="View" source="view_count"/>
                <TextField label="Like" source="like_count"/>
                <ReferenceManyField label="Collections" reference="collection_products" target="product_id" sort={{field: "createdAt", order: 'DESC'}} sortable={false}>
                    <SingleFieldList linkType={false} >
                        <ChipField source="collection.title" />
                    </SingleFieldList>
                </ReferenceManyField>
                <TextField label="Category" source="category" sortable={false}/>
                <TextField label="Subcategory" source="subcategory.name" sortable={false}/>
                <TextField label="Size" source="size_value.name" sortable={false}/>
                <TextField label="Title" source="title" sortable={false}/>
                <TextField label="Brand" source="brand" sortable={false}/>
                <ArrayField label="Styles" source="productBrand.styles" sortable={false}>
                    <SingleFieldList linkType={false}>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ArrayField>
                <TextField label="Gender" source="gender" sortable={false}/>
                <SelectField label="Condition" source="condition" choices={conditionChoices} optionValue="value" sortable={false}/>
                <TextField label="OriginalPrice" source="original_price" sortable={false}/>
                <TextField label="Price" source="price"/>
                <TextField label="Shipping_fee" source="shipping_fee"/>
                <TextField label="Shipping_fee_island" source="shipping_fee_island"/>
                <TextField label="Status" source="status" sortable={false}/>
                <DateField label="Sold At" source="soldAt" sortable={false}/>
                <DateField label="Created at" source="createdAt" />
                <DateField label="Deleted at" source="deletedAt" sortable={false}/>
                <BumpButton></BumpButton>
            </Datagrid>
        </List>
        <OutOfAppExtractionModal basePath={basePath} {...props} open={isOutOfAppModalOpen} onClose={() => setOutOfAppModalOpen(false)}/>
        <BulkCreateModal open={bulkCreateModalOpen} onClose={() => setBulkCreateModalOpen(false)}/>
    </Fragment>);
});

const ColorInput = ({record, all_colors}) => {
    if (!record.color_id) {
        return null;
    }

    return all_colors?.length > 0 && <SelectInput source="color_id" choices={all_colors}/>
}

export const CategoriesSelectInput = ({record, all_categories, ...props}) => {

    if (!record || !all_categories) {
        return <div/>
    }

    return (
        <div>
            <SelectInput source="category" optionText="name" optionValue="name" choices={all_categories}/>
            <FormDataConsumer>
                {({formData, ...rest}) => {
                    if (!formData) return <div/>
                    const selected_category = all_categories.find((item) => item.name === formData.category)

                    const subcategories = selected_category?.subcategories;
                    return subcategories &&
                        <SelectInput source="subcategory_id" allowEmpty={true} choices={subcategories}/>
                }}
            </FormDataConsumer>
            <FormDataConsumer>
                {({formData, ...rest}) => {
                    if (!formData) return <div/>
                    const selected_category = all_categories.find((item) => item.name === formData.category);
                    const selected_subcategory = selected_category?.subcategories.find((item) => item.id === formData.subcategory_id);
                    const size_values = selected_subcategory?.size_type?.size_values;
                    return (selected_subcategory && size_values) &&
                        <SelectInput source="size_value_id" choices={size_values}/>
                }}
            </FormDataConsumer>
        </div>
    )
}

const CategoriesSelectInputForCreate = ({all_categories}) => {
    if (!all_categories) {
        return <div/>
    }
    if (typeof all_categories === 'string') { //가끔 all_categories가 string으로 오는 문제 해
        all_categories = JSON.parse(all_categories)
    }
    return (
        <div>
            <SelectInput source="category" optionText="name" optionValue="name" choices={all_categories}/>
            <FormDataConsumer>
                {({formData, ...rest}) => {
                    if (!formData) return <div/>
                    const selected_category = all_categories.find((item) => item.name === formData.category)
                    const subcategories = selected_category?.subcategories;
                    return subcategories &&
                        <SelectInput source="subcategory_id" allowEmpty={true} choices={subcategories}/>
                }}
            </FormDataConsumer>
            <FormDataConsumer>
                {({formData, ...rest}) => {
                    if (!formData) return <div/>
                    const selected_category = all_categories.find((item) => item.name === formData.category);
                    const selected_subcategory = selected_category?.subcategories.find((item) => item.id === formData.subcategory_id);
                    const size_values = selected_subcategory?.size_type?.size_values;
                    return (selected_subcategory && size_values) &&
                        <SelectInput source="size_value_id" choices={size_values}/>
                }}
            </FormDataConsumer>

        </div>
    )
}

export const ProductEdit = withStyles(styles)(({classes = {}, ...props}) => {
    const data = useFetch(`${API_URL}/all_categories`);
    const colorData = useFetch(`${API_URL}/colors`);
    const all_categories = data?.result.all_categories;
    const all_colors = colorData?.result;

    return (
    <Edit {...props}>
        <SimpleForm toolbar={<CustomToolbar />}>
            <TextInput source="id" disabled/>
            <ReferenceField label="Seller" source="seller_id" reference="users" link="show">
                <UserLinkField/>
            </ReferenceField>
            <BooleanInput
                label="Is visible"
                source="is_visible"
                defaultValue={true}/>
            <FormDataConsumer>
                {
                    ({formData, ...rest}) => {
                        if(!formData.is_visible) {
                            return <WarningText>is_visible: false 적용시 seller는 이 상품에 '상단업' 기능을 사용 할 수 없게됩니다.</WarningText>
                        }
                    }
                }
            </FormDataConsumer>
            <TextInput label="External Url" source="external_url" validate={[validateUrl({allowEmpty: true})]} resettable style={{width:'100%'}}/>
            <FormDataConsumer>
                {({ formData, ...rest }) =>
                    <SelectInput allowEmpty={formData.scheduled_update === null} label="Scheduled_update" source="scheduled_update"
                        format={v => v ? "true"
                            : v === false ? "false"
                            : "" }
                        disabled={formData.scheduled_update === null}
                        choices={[
                            {
                                id: "true", name: 'true'
                            }, {
                                id: "false", name: 'false'
                            },
                        ]}
                    />
                }
            </FormDataConsumer>

            <CategoriesSelectInput all_categories={all_categories} all_colors={all_colors}/>
            <ColorInput all_colors={all_colors}/>
            <ReferenceInput source="brand_id" reference="brands" perPage={100} sort={{field: 'name', order: 'ASC'}} allowEmpty>
                <AutocompleteInput />
            </ReferenceInput>
            <SelectInput source="gender" optionText="name" optionValue="name" choices={[{'name': 'unisex'}, {'name': 'men'}, {'name': 'women'}]}/>
            <SelectInput source="condition" optionValue="value" choices={conditionChoices}/>
            <TextInput label="Title" source="title"/>
            <TextInput multiline label="Description" source="description" />
            <div/>
            <NumberInput label="OriginalPrice" source="original_price"/>
            <NumberInput label="Price" source="price" />
            <FormDataConsumer>
                {({ formData, ...rest }) =>
                    <NumberInput {...rest} label="Shipping_fee" source="shipping_fee" />
                }
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData, ...rest }) =>
                    <NumberInput {...rest} label="Shipping_fee_island" source="shipping_fee_island" />
                }
            </FormDataConsumer>
            <SelectInput label="Status" source="status" choices={[
                {
                    id: 'selling', name: 'selling'
                }, {
                    id: 'sold', name: 'sold'
                }
            ]}/>
            <FormDataConsumer>
                {
                    ({formData, ...rest}) => {
                        if(formData.status === 'sold') {
                            return <NullableBooleanInput label="sold_at_fruitsfamily" source="sold_at_fruitsfamily" />
                        }
                    }
                }
            </FormDataConsumer>
            <DateField label="Created at" source="createdAt"/>
            <SortableImageInput source="images" accept="image/*" previewUrlFormat={url => {
                const isBlobUrl = typeof url === 'string' && url.match(/^blob:/);
                return isBlobUrl ? url : getResizedURL(url, 'width620');
            }} />
            <ReferenceManyField
                reference="product_bumps"
                target="product_id"
                label="Bumped(상단업) history"
                sort={{field: "createdAt", order: "DESC"}}
                perPage={10}
            >
                <Datagrid>
                    <TextField source="id"/>
                    <DateField label="Bumped Date" source="createdAt" showTime/>
                    <ReferenceField label="Bumped By" source="seller_id" reference="users" link="show" allowEmpty>
                        <UserLinkField/>
                    </ReferenceField>
                </Datagrid>
            </ReferenceManyField>

            {/* Webapp and content feed links */}
            <FunctionField
                label="Deep Link"
                render={record => (
                    <a href={productUrl(WEBAPP_URL, record)} target="_blank">
                        {productUrl(WEBAPP_URL, record).toString()}
                    </a>
                )}
            />
            <BooleanInput label={'네고 여부'} source="is_negotiation" />
        </SimpleForm>
    </Edit>
)})

export const ProductShow = withStyles(styles)(({classes = {}, ...props}) => (
    <Show aside={<Aside/>} {...props}>
        <SimpleShowLayout>
            <TextField source="id"/>
            <ReferenceField label="Seller" source="seller_id" reference="users" link="show">
                <UserLinkField/>
            </ReferenceField>
            <BooleanField source="is_visible" label="is_visible"/>
            <TextField label="Category" source="category" className={classes.inline}/>
            <TextField label="Sub Category" source="sub_category" className={classes.inline}/>
            <TextField label="Gender" source="gender"/>
            <TextField label="Title" source="title"/>
            <RichTextField label="Description" source="description" className={classes.inline}/>
            <div/>
            <TextField label="Brand" source="brand" className={classes.inline}/>
            <TextField label="Size" source="size" className={classes.inline}/>
            <NumberField label="OriginalPrice" source="original_price" className={classes.inline}/>
            <NumberField label="Price" source="price" className={classes.inline}/>
            <NumberField label="Shipping_fee" source="shipping_fee" className={classes.inline}/>
            <TextField label="Status" source="status"/>
            <DateField label="Created at" source="createdAt"/>
            <h3>Images</h3>
            <ImageField label="Images" source="resizedSmallImages" src="url"/>
            <ReferenceManyField
                reference="product_bumps"
                target="product_id"
                label="Bumped(상단업) history"
                sort={{field: "createdAt", order: "DESC"}}
                perPage={10}
            >
                <Datagrid>
                    <TextField source="id"/>
                    <DateField label="Bumped Date" source="createdAt" showTime/>
                    <ReferenceField label="Bumped By" source="seller_id" reference="users" link="show" allowEmpty>
                        <UserLinkField/>
                    </ReferenceField>
                </Datagrid>
            </ReferenceManyField>
            <ArrayField source="negotiations">
                <Datagrid>
                    <TextField source="id"/>
                    <DateField label="네고일자" source="createdAt" showTime/>
                    <DateField label="네고만료일" source="payment_expired_at" showTime/>
                    <TextField label="상태" source="status"/>
                    <TextField label="네고 가격" source="negotiation_price"/>
                    <TextField label="네고 차액" source="difference_price"/>
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
))

export class ProductCreate extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount = async () => {
        const res = await fetch(`${API_URL}/all_categories`, {
            method: 'GET',
            headers: new Headers({
                'content-type': 'application/json',
                Authorization: `bearer ${loadAccessToken()}`
            }),
        })
        const {result} = await res.json()
        this.all_categories = result.all_categories
        this.all_brands = result.all_brands;

        // Recieve productMetadata data from <OutOfAppExtractionModal>
        const productMetadata = this.props?.location?.state?.productMetadata;
        // console.log('productMetadata', productMetadata);
        if (productMetadata) {
            this.canBeScheduledUpdated = productMetadata.canBeScheduledUpdated;
            let images = [];
            if (Array.isArray(productMetadata.images)) {
                const imageBlobs = productMetadata.images.map((image) => {
                    const imageBlob = base64toBlob(image.imageData.data, image.imageData.contentType);
                    const blobUrl = URL.createObjectURL(imageBlob);
                    return {blob: imageBlob, blobUrl};
                });

                imageBlobs.forEach((data, idx) => {
                    const imageData = {rawFile: data.blob, url: data.blobUrl, title: data.blobUrl.pathname};
                    images.push(imageData);
                })
            }

            this.prefilledForm = {
                images: images,
                external_url: productMetadata.externalUrl,
                scheduled_update: productMetadata.canBeScheduledUpdated ? "true" : null,
                title: productMetadata.title,
                description: productMetadata.description,
                brand_id: (() => {
                    if(productMetadata.brand) {
                        const lowerBrandName = productMetadata.brand.toLowerCase();
                        const existedBrand = this.all_brands?.find(brand => brand.name.toLowerCase() === lowerBrandName);
                        return existedBrand?.id;
                    }
                })(),
                price: productMetadata.price,
                status: productMetadata.status !== null ? productMetadata.status : 'selling',
                is_visible: true
            }
        } else {
            this.prefilledForm = {
                status: 'selling',
                is_visible: true
            }
        }

        setTimeout(() => {
            this.forceUpdate()
        }, 1000)
    }

    render() {
        return <CreateController {...this.props} undoable={false}>
            {controllerProps => {
                return controllerProps.record ? (
                    <SimpleForm initialValues={this.prefilledForm} {...controllerProps} redirect="list">
                        <ReferenceInput label="User" source="user_id" reference="users">
                            <AutocompleteInput source="user_id" optionText={choice => `${choice?.username}`}/>
                        </ReferenceInput>
                        <BooleanInput source="is_visible"/>
                        <FormDataConsumer>
                            {
                                ({formData, ...rest}) => {
                                    if(!formData.is_visible) {
                                        return <WarningText>is_visible: false 적용시 seller는 이 상품에 '상단업' 기능을 사용 할 수 없게됩니다.</WarningText>
                                    }
                                }
                            }
                        </FormDataConsumer>
                        <TextInput label="External Url" source="external_url" validate={[validateUrl({allowEmpty: true})]}
                            resettable style={{width:'100%'}}/>
                        <SelectInput allowEmpty={!this.canBeScheduledUpdated} label="Scheduled_update" source="scheduled_update"
                            disabled={!this.canBeScheduledUpdated}
                            choices={[
                            {
                                id: "true", name: 'true'
                            }, {
                                id: "false", name: 'false'
                            },
                            ]}
                        />
                        <CategoriesSelectInputForCreate all_categories={this.all_categories} />
                        <ReferenceInput source="brand_id" reference="brands" perPage={100} sort={{field: 'name', order: 'ASC'}} allowEmpty>
                            <AutocompleteInput />
                        </ReferenceInput>
                        <SelectInput source="gender" optionText="name" optionValue="name" choices={[{'name': 'unisex'}, {'name': 'men'}, {'name': 'women'}]}/>
                        <SelectInput source="condition" optionValue="value" choices={conditionChoices}/>
                        <TextInput label="Title" source="title" resettable />
                        <TextInput multiline label="Description" source="description" />
                        <div/>
                        <NumberInput label="OriginalPrice" source="original_price" />
                        <NumberInput label="Price" source="price" />
                        <FormDataConsumer>
                            {({ formData, ...rest }) =>
                                <NumberInput {...rest} label="Shipping_fee" source="shipping_fee"/>
                            }
                        </FormDataConsumer>
                        <FormDataConsumer>
                            {({ formData, ...rest }) =>
                                <NumberInput {...rest} label="Shipping_fee_island" source="shipping_fee_island" />
                            }
                        </FormDataConsumer>
                        <SelectInput label="Status" source="status" choices={[
                            {
                                id: 'selling', name: 'selling'
                            }, {
                                id: 'sold', name: 'sold'
                            }
                        ]}/>
                        <FormDataConsumer>
                            {
                                ({formData, ...rest}) => {
                                    if(formData.status === 'sold') {
                                        return <NullableBooleanInput label="sold_at_fruitsfamily" source="sold_at_fruitsfamily" />
                                    }
                                }
                            }
                        </FormDataConsumer>

                        {/* Images =============================== */}
                        <SortableImageInput source="images" accept="image/*" separateRawFiles={false} />
                    </SimpleForm>
                ) : null
            }}
        </CreateController>
    }
}


const AsideOrderTable = styled.table`
    &, tr, th, td {
        border: 1px solid black;
        border-collapse: collapse;
    }
    th, td {
        padding: 1px 4px;
    }
    thead tr {
        background-color: #eeeeee;
        font-size: 0.9rem;
    }
    tbody {
        font-size: 0.8rem;
        tr:nth-child(even) {
            background-color: #ededed;
        }
    }
`;

const Aside = ({record}) => (
    <div style={{width: 200, margin: '1em'}}>
        <div>
            <h2>Order</h2>
            {record && (
                <AsideOrderTable>
                    <thead>
                        <tr>
                            <th>OrderId</th>
                            <th>Buyer</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {record?.orders?.map(order => {
                            return (
                                <tr>
                                    <td><Link to={`/orders/${order.id}`} >{order.id}</Link></td>
                                    <td><UserLinkField record={order.user}/></td>
                                    <td><TextField record={order} source="status"/></td>
                                </tr>
                            )}
                        )}
                    </tbody>
                </AsideOrderTable>
            )}
        </div>
        <br/>

        <div>
            <h2>Review</h2>
            {record && record.review && (
                <div>
                    <p>Rating</p>
                    <StarRatingField record={record.review} source="rating"/>
                    <p>text</p>
                    <TextField record={record.review} source="text"/>
                </div>
            )}
        </div>
    </div>
);
