import React from 'react';
import {
    AutocompleteInput,
    Datagrid,
    DateField,
    DateInput,
    Filter,
    ImageField,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    TextField,
    TextInput
} from 'react-admin';
import {withStyles} from "@material-ui/core";

import UserLinkField from "../components/UserLinkField";

const reportTypeChoices = [
    { id: 'post', name: 'post'},
    { id: 'post_comment', name: 'post_comment'},
    { id: 'product', name: 'product'},
    { id: 'user', name: 'user'},
    { id: 'system', name: 'system'},
]

const reportModerationDecisionChoices = [
    { id: 'HIDDEN', name: 'HIDDEN'},
    { id: 'IGNORED', name: 'IGNORED'},
    { id: 'null', name: 'null'},
]

// component styling
const styles = {
    image: {
        '& img': {
            width: 80,
            height: 106.6667, // same 3 by 4 aspect ratio as in the mobile app
            objectFit: 'cover'
        }
    }
};

/**
 * Styling for a report row
 * @param record the current data record
 * @return the style properties
 */
function reportRowStyle(record) {
    return {
        backgroundColor: record.moderationDecision ? undefined : '#fff4e5'
    };
}

/**
 * Sub-component for filtering a list of reports.
 * @param props the react admin Filter component properties
 * @return {JSX.Element}
 * @constructor
 */
function ReportFilter(props) {
    return (
        <Filter {...props}>
            {/* generic report details */}
            <DateInput source="createdAt_from" label="created from" alwaysOn/>
            <DateInput source="createdAt_until" label="created until" alwaysOn/>
            <TextInput label="reporter_id" source="reporter_id" alwaysOn />
            <ReferenceInput source="reporter_id" reference="users" label="Reporter" alwaysOn>
                <AutocompleteInput optionText="nickname"/>
            </ReferenceInput>
            <TextInput source="reason" alwaysOn/>
            <SelectInput choices={reportTypeChoices} source="report_type" alwaysOn />
            <SelectInput choices={reportModerationDecisionChoices} source="moderationDecision" alwaysOn />

            {/* content report */}
            <ReferenceInput source="user_id" reference="users" alwaysOn>
                <AutocompleteInput optionText="nickname"/>
            </ReferenceInput>
            <TextInput label="user_id" source="user_id" alwaysOn />
            <ReferenceInput source="product_id" reference="products" alwaysOn>
                <AutocompleteInput optionText="title"/>
            </ReferenceInput>
            <TextInput label="product_id" source="product_id" alwaysOn />
            <ReferenceInput source="post_id" reference="posts" alwaysOn>
                <AutocompleteInput optionText="description"/>
            </ReferenceInput>
            <TextInput label="post_id" source="post_id" alwaysOn />
        </Filter>
    );
}

/**
 * Component for a list of reports.
 * @param props the react admin List component properties
 * @return {JSX.Element}
 * @constructor
 */
function ReportList({classes, ...props}) {
    // build the UI elements
    return (
        <List bulkActionButtons={false} filters={<ReportFilter/>} sort={{field: 'id', order: 'DESC'}} {...props}>
            <Datagrid rowClick="edit" rowStyle={reportRowStyle}>
                {/* generic report details */}
                <DateField source="createdAt" label="Report Date"/>
                <ReferenceField source="reporter_id" reference="users">
                    <UserLinkField/>
                </ReferenceField>
                <TextField source="reason"/>

                {/* user report */}
                <ReferenceField source="user.id" reference="users" label="User" sortable={false} allowEmpty>
                    <UserLinkField/>
                </ReferenceField>

                {/* product report */}
                <ImageField source="product.resizedSmallImages.0.url" label="Product Image" sortable={false} className={classes.image}/>
                <TextField source="product.title" label="Product" />
                <ReferenceField source="product.seller_id" reference="users" label="Product Seller" sortable={false} allowEmpty>
                    <UserLinkField/>
                </ReferenceField>

                {/* post report */}
                <ImageField source="post.resizedSmallImages.0.url" label="Post Image" sortable={false} className={classes.image}/>
                <TextField source="post.description" label="Post"/>
                <ReferenceField source="post.user_id" reference="users" label="Post Author" sortable={false} allowEmpty>
                    <UserLinkField/>
                </ReferenceField>

                {/* post comment report */}
                <TextField source="post_comment.text" label="Comment" sortable={false}/>
                <ReferenceField source="post_comment.user_id" reference="users" label="Comment Author" sortable={false} allowEmpty>
                    <UserLinkField/>
                </ReferenceField>

                {/* moderation status */}
                <DateField source="moderatedAt"/>
                <TextField source="moderationDecision"/>
            </Datagrid>
        </List>
    );
}

export default withStyles(styles)(ReportList);
